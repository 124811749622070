import React from 'react';
import {Link} from 'react-router-dom';
import {Menu, Image, Button} from 'semantic-ui-react';
import deltaLogoGreen from '../../../images/deltaLogoGreen.png';

const NavBar: React.FC = () => {
	return (
		<Menu size="large" fixed="top" inverted>
			<Menu.Item>
				<Image size="mini" src={deltaLogoGreen} />
			</Menu.Item>
			<Menu.Item header>Allocation Dashboard</Menu.Item>
			<Menu.Menu position="right">
				<Menu.Item>
					<Button as={Link} to="/dashboard" inverted color="green">
						Dashboard
					</Button>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	);
};

export default NavBar;
