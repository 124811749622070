import React from 'react';
import styles from './style.module.css';
import {useLocation} from 'react-router-dom';

function MadeWithLove() {
	let location = useLocation();

	const footerStyle = location.pathname === '/dashboard' ? styles.footerDash : '';
	const h3Style = location.pathname === '/dashboard' ? styles.h3Dash : '';
	return (
		<footer className={`${styles.footer} ${footerStyle}`}>
			<h4 className={`${h3Style}`}>
				Made with ❤️ by{' '}
				<a className={styles.a} href="https://delta.nitt.edu/" target="_blank" rel="noopener noreferrer">
					Delta Force
				</a>{' '}
			</h4>
		</footer>
	);
}

export default MadeWithLove;
