import React, {useCallback, useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Menu} from 'semantic-ui-react';
import {IDomain} from '../../../contexts/DomainsContext';
import {axiosInstance} from '../../../utils/axios';
import {AdminContext} from '../AdminContext';
import AllocationTab from './AllocationTab';

function Allocation({domains}: any) {
	const [finalList, setFinalList]: [any, any] = useState({});

	const {clearState, setDomainLetter, run} = useContext(AdminContext);
	// active tab
	const [activeItem, setActiveItem] = useState(domains ? domains[0].shortName : '');
	// active tab handle
	const handleItemClick = (e: any, {name}: any) => {
		setActiveItem(name);
		clearState();
		setDomainLetter(name[0]);
	};

	// handle to fetch finalMentorsWithMentees List
	const getfinalMentorsWithMentees = useCallback(() => {
		axiosInstance
			.get(`/allocate/preview`)
			.then((response) => {
				setFinalList(response.data.finalMentorsWithMentees);
			})
			.catch((e) => {
				console.log(e);
				if (e.message) {
					toast.error(e.message);
				} else {
					toast.error(e.response);
				}
			});
	}, []);

	useEffect(() => {
		getfinalMentorsWithMentees();
		// setting domain letter
		setDomainLetter(activeItem[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getfinalMentorsWithMentees, run]); // run state is added here to run this side effect forcefully

	return (
		<>
			<Menu style={{position: 'relative'}} fixed="top" inverted widths={domains.length}>
				{domains.map((d: IDomain, i: number) => (
					<Menu.Item
						key={i}
						name={d.shortName}
						color="green"
						active={activeItem === d.shortName}
						onClick={handleItemClick}
					/>
				))}
			</Menu>

			<AllocationTab domainList={finalList[`${activeItem[0].toLowerCase()}`]} />
		</>
	);
}

export default Allocation;
