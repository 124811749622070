import React, {useEffect, useState, useCallback, useContext, useRef} from 'react';
import {useHistory} from 'react-router-dom';

import deltaIcon from '../../../images/deltaLogoGreen.png';
import {axiosInstance} from '../../../utils/axios';
import {toast} from '../../Toast/useToast';
import {userContext} from '../../../contexts/UserContext';

import styles from './login.module.css';

enum AuthStatusEnum {
	START,
	WAITING, // WAITING FOR DAUTH RESPONSE
	ACCEPTED,
	REJECTED,
	AUTH, // sending dauth data to backend
	ERROR, // some error occurred, ask user to try again
}

export const DAuthCallBack = () => {
	const history = useHistory();
	const {saveUser} = useContext(userContext);

	const [authStatus, setAuthStatus] = useState(AuthStatusEnum.START);
	let isAuthRequestPending = useRef(false);

	// Generates A Dauth Auth url
	const generateDauthAuthorizeUrl = async () => {
		try {
			const resp = await axiosInstance.get('/auth/dauth/authorize');
			if (resp.status === 200) {
				const dauthAuthorizeURL = resp.data.redirectUrl;
				return dauthAuthorizeURL;
			} else {
				toast.error('Something went wrong. Try again later.');
			}
		} catch (err) {
			process.env.NODE_ENV === 'development' && console.log('Something went wrong', err);
			toast.error('Something went wrong. Try again later.');
		}
		return '';
	};

	const sendAuthCodeToServer = useCallback(
		async (code: string) => {
			try {
				// some times we get multiple messages from pop-up
				// causing the app to send consent request to the server, which
				// results in a 404
				if (!isAuthRequestPending.current) {
					isAuthRequestPending.current = true;
					setAuthStatus(AuthStatusEnum.AUTH);
					const resp = await axiosInstance.post('/auth/dauth/callback', {
						code,
					});
					saveUser(resp.data.user);

					// if the user is logging in for the first time,
					// redirect them to register page
					// else redirect them to dashboard page
					if (resp.data.user.isNewUser) {
						return history.push('/auth/register');
					}
					history.push('/dashboard');
				} else {
				}
			} catch (err: any) {
				// if some error occurred its mostly because
				// the code was invalid / network issues
				//
				// setting the state as error
				// and we need to ask the user to try again later
				console.log(err);
				if (err.response) {
					return toast.error(err.response.data.message);
				}
				return toast.error('something went wrong. Try again later.');
			}
		},
		[history, saveUser, isAuthRequestPending]
	);

	const BASE_URL = window.location.origin;
	/**
	 * Recieves message from the pop-up
	 */
	const receiveMessage = useCallback(
		async (event: MessageEvent<any>) => {
			// console.log(event);
			// Do we trust the sender of this message? (might be
			// different from what we originally opened, for example).
			if (event.origin !== BASE_URL) {
				return;
			}
			const {data} = event;
			process.env.NODE_ENV === 'development' && data.source === 'dauth-login-callback' && console.log(data);
			// // if we trust the sender and the source is our popup
			if (data.source === 'dauth-login-callback') {
				// if the user has a valid authorization code
				if (!data.code) setAuthStatus(AuthStatusEnum.REJECTED);
				else {
					setAuthStatus(AuthStatusEnum.ACCEPTED);
					sendAuthCodeToServer(data.code);
				}
				// TODO: need to see if state is same
				// and add some expiry for callback
			}
		},
		[BASE_URL, sendAuthCodeToServer]
	);

	// let windowObjectReference: Window | null = null;
	// let previousUrl: string | null = null;

	const [windowObjectReference, setWindowObjectReference] = useState<Window | null>(null);
	const [previousUrl, setPreviousUrl] = useState<string | null>(null);

	const openSignInWindow = useCallback(
		(url: string, name: string) => {
			// remove any existing event listeners
			window.removeEventListener('message', receiveMessage);

			// window features
			const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

			if (windowObjectReference === null || windowObjectReference.closed) {
				/* 	
				if the pointer to the window object in memory does not exist
				or if such pointer exists but the window was closed 
			*/
				setWindowObjectReference(window.open(url, name, strWindowFeatures));
			} else if (previousUrl !== url) {
				/* 	
			if the resource to load is different,
			then we load it in the already opened secondary window and then
			we bring such window back on top/in front of its parent window. 
			*/
				setWindowObjectReference(window.open(url, name, strWindowFeatures));
				windowObjectReference?.focus();
			} else {
				/* 	
				else the window reference must exist and the window
				is not closed; therefore, we can bring it back on top of any other
				window with the focus() method. There would be no need to re-create
				the window or to reload the referenced resource. 
			*/
				windowObjectReference.focus();
			}

			setAuthStatus(AuthStatusEnum.WAITING);

			// add the listener for receiving a message from the popup
			window.addEventListener('message', receiveMessage, false);
			// assign the previous URL
			setPreviousUrl(url);
		},
		[previousUrl, receiveMessage, windowObjectReference]
	);

	const generateDauthStringAndOpenUrl = useCallback(async () => {
		const dauthURL = await generateDauthAuthorizeUrl();
		if (dauthURL) {
			process.env.NODE_ENV === 'development' && console.log(dauthURL);
			openSignInWindow(dauthURL, 'dauthURL');
		}
	}, [openSignInWindow]);

	/**
	 *
	 * USE EFFECT
	 *
	 * on page load, call dauth and verify user
	 * and handle dauth response appropriately
	 *
	 */
	useEffect(() => {
		// if (!loading) return;
		generateDauthStringAndOpenUrl();
	}, [generateDauthStringAndOpenUrl]);

	return (
		<>
			<div className={styles.loginContainer} style={{padding: '5rem 0.5rem', textAlign: 'center'}}>
				<>
					<img className={styles.spinningImage} src={deltaIcon} alt="delta icon" />
					<p style={{height: '150px', width: '150px'}} />
				</>
				{authStatus === AuthStatusEnum.START ? (
					<>Setting things up ...</>
				) : authStatus === AuthStatusEnum.WAITING ? (
					<div className={styles.contentBox}>
						<p>
							Unable to see the window ? <br />
							Make sure you allow pop-ups in settings
							<p onClick={() => generateDauthStringAndOpenUrl()} className={styles.link}>
								Click Here
							</p>
						</p>
					</div>
				) : authStatus === AuthStatusEnum.ACCEPTED || authStatus === AuthStatusEnum.AUTH ? (
					<>Finishing things up...</>
				) : authStatus === AuthStatusEnum.REJECTED ? (
					<div>
						Opps ! Looks like you denied permissions
						<p>
							<span onClick={() => generateDauthStringAndOpenUrl()}>Click Here</span>
							to try again{' '}
						</p>
					</div>
				) : authStatus === AuthStatusEnum.ERROR ? (
					<div>
						Looks like something went wrong.
						<p>
							<span onClick={() => generateDauthStringAndOpenUrl()} className={styles.link}>
								Click Here
							</span>{' '}
							to try again{' '}
						</p>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
};
