import axios from "axios";
import { config } from "../config/config";

export const axiosInstance = axios.create({
  baseURL: config.backEndOrigin || "http://localhost:3000",
  withCredentials: true
});
// axiosInstance.interceptors.response.use(function (response) {
//   if(response.status === 401) {
//       // your failure logic
//   }
//   return response;
// }, function (error) {
//   return Promise.reject(error);
// });
