import React, {useContext} from 'react';
import {Link, Redirect, useHistory} from 'react-router-dom';
import {Container, Header, Image} from 'semantic-ui-react';
import {axiosInstance} from '../../../utils/axios';
// import {registerForm} from './registerForm';
// import styles from "./register.module.css";
import styles from './Register.module.css';
// import { InductionInfo } from "./InductionInfo";
import {RegisterForm} from './RegisterForm';
import deltaIcon from '../../../images/deltaLogoGreen.png';
import Spinner from '../../Spinner';
import {userContext} from '../../../contexts/UserContext';
import {toast} from '../../Toast/useToast';

const Register = () => {
	const {isLoggedIn, isNewUser, loading, deleteUser} = useContext(userContext);

	const history = useHistory();

	if (loading) {
		return <Spinner />;
	}

	// if loggedin push him/her to dashboard
	if (isLoggedIn && !isNewUser) {
		return <Redirect to="/dashboard" />;
	}

	const handleLogout = (e: any) => {
		e.preventDefault();
		axiosInstance
			.post('/auth/logout')
			.then((res) => {
				history.push('/');
				deleteUser();
				toast.dark('Logged out successfully');
			})
			.catch((err) => console.log(err));
	};

	return (
		<Container text className={styles.registerContainer} style={{maxWidth: '650px !important'}}>
			<Header as="h1" size="huge" style={{color: '#f1f1f1', zoom: '1.3', paddingLeft: '8px'}}>
				Register
				<Image src={deltaIcon} floated="right" className={styles.sexyAnimation} />
			</Header>
			<br />
			<RegisterForm />
			<br />
			<Link
				to="/logout"
				onClick={handleLogout}
				style={{maxWidth: '70%', display: 'inline-block', color: '#28a745'}}>
				Logout
			</Link>
			<Link to="/" style={{float: 'right', marginRight: '1rem'}}>
				Return
			</Link>
			<br />
		</Container>
	);
};

export {Register};
