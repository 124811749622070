import {useRef} from 'react';
import {CustomAccordion} from '../../../../components/CustomAccordion';
import {MenteeSubmissionCard} from '../../../../components/SubmissionCard';
import {
	ISubmission,
	ISubmissionWithDomain,
	ITask,
	ITasksWithDomain,
} from '../../../../components/SubmissionCard/submissionsInterfaces';

interface ITaskSubmission {
	[key: number]: ISubmission;
}

export const MenteeSubmissions = (props: any) => {
	const taskSubmissions = useRef<ITaskSubmission>({});

	props.dashboardRes.submissions.forEach((domain: ISubmissionWithDomain) => {
		if (domain.shortName !== props.domain) return;
		domain.submissions.forEach((submission: ISubmission) => {
			if (submission.githubUrl) {
				taskSubmissions.current[submission.taskId] = submission;
			}
		});
	});

	return props.dashboardRes.tasks.map((domain: ITasksWithDomain) => {
		if (domain.shortName !== props.domain) return null;
		return domain.tasks.map((task: ITask) => (
			<CustomAccordion key={task.id} title={task.name}>
				<MenteeSubmissionCard task={task} submission={taskSubmissions.current[task.id]} />
			</CustomAccordion>
		));
	});
};
