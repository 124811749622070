import React, {useContext, useEffect, useState} from 'react';
import {Divider} from 'semantic-ui-react';
import {CustomAccordion} from '../../../components/CustomAccordion';
import Spinner from '../../../components/Spinner';
import {userContext} from '../../../contexts/UserContext';
import {axiosInstance} from '../../../utils/axios';

export const Announcements = () => {
	const {user} = useContext(userContext);
	const [dashboardRes, setDashboardRes] = useState<any>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchDashboard = async () => await axiosInstance.get(`/dashboard/${user.id}`);
		fetchDashboard()
			.then((res) => {
				setDashboardRes(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}, [user.id]);

	if (loading) return <Spinner />;

	return (
		<div>
			<h1>Announcements</h1>
			<Divider />
			{dashboardRes?.announcements.map((announcement: any, i: number) => (
				<CustomAccordion defaultOpen={i === 0} key={announcement.name} title={announcement.name}>
					<div style={{margin: '18px 0'}} dangerouslySetInnerHTML={{__html: announcement.post.html}} />
				</CustomAccordion>
			))}
		</div>
	);
};
