import React, {createContext, useEffect, useState, useCallback} from 'react';
import {axiosInstance} from '../utils/axios';

export interface IDomain {
	id: number;
	name: string;
	shortName: string;
}

export const DomainsContext = createContext<any>(null);

const DomainsContextProvider: React.FC = ({children}) => {
	const [domains, setDomains]: [IDomain[], (domains: IDomain[]) => void] = useState<IDomain[]>([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	const getDomains = useCallback(() => {
		axiosInstance
			.get<IDomain[]>(`/domains`)
			.then((response) => {
				setDomains(response.data);
				setLoading(false);
			})
			.catch((e) => {
				if (e.response) setError(e.response);
				else setError(e.message);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		getDomains();
	}, [getDomains]);

	return <DomainsContext.Provider value={{domains, error, loading}}>{children}</DomainsContext.Provider>;
};

export default DomainsContextProvider;
