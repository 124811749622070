import React, {useEffect} from 'react';
import Matrix from '../../utils/matrix';
import LandingPage from './LandingPage/LandingPage';
import WhyDeltaPage from './WhyDeltaPage/WhyDeltaPage';
import DomainsPage from './DomainsPage/DomainsPage';
import ProjectsPage from './ProjectsPage/ProjectsPage';

const Home = () => {
	useEffect(() => {
		const matrix = new Matrix();
		matrix.start();
		return () => {
			setTimeout(() => {
				matrix.stop();
			}, 0);
			return;
		};
	}, []);

	return (
		<>
			<LandingPage />
			<WhyDeltaPage />
			<DomainsPage />
			<ProjectsPage />
		</>
	);
};

export default Home;
