import React, {useContext} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Container, Header, Image, Button} from 'semantic-ui-react';
import styles from './login.module.css';
import deltaIcon from '../../../images/deltaLogoGreen.png';
import {userContext} from '../../../contexts/UserContext';
import Spinner from '../../Spinner';

const Login = () => {
	const {isLoggedIn, loading, isNewUser} = useContext(userContext);

	if (loading) {
		return <Spinner />;
	}

	// if loggedin push them to dashboard
	if (isLoggedIn) {
		if (isNewUser) return <Redirect to="/auth/info" />;
		else return <Redirect to="/dashboard" />;
	}

	return (
		<Container text className={styles.loginContainer} style={{maxWidth: '650px !important'}}>
			<Header as="h1" size="huge" style={{color: '#f1f1f1', fontSize: '36px', paddingLeft: '0'}}>
				Login
				<Image src={deltaIcon} floated="right" className={styles.sexyAnimation} />
			</Header>
			<br />
			<p style={{maxWidth: '90%', margin: '0'}}>
				Want to write code for D-Auth ? Start the journey by logging into it.
			</p>
			<br />
			<Button
				as={Link}
				inverted
				to="/auth/callback"
				color="green"
				style={{
					marginBottom: '7rem',
					display: 'block',
					margin: '1rem auto 5rem',
				}}>
				Login with D-Auth
			</Button>
			<div className={styles.moreStuff}>
				<Link to="/" style={{float: 'right', marginRight: '1rem'}}>
					Return
				</Link>
			</div>
		</Container>
		// </div>
	);
};

export {Login};
