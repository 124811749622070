import React, {createContext, useCallback, useState, useEffect} from 'react';
import {axiosInstance} from '../utils/axios';

export interface IUser {
	id: number;
	isNewUser: boolean;
	name: string;
	rollNumber: string;
	gender: string;
	email: string;
	hashedEmail: string;
	contactNumber: string;
	github: string;
	role: string;
	interests: string;
	deleted: boolean;
	createdAt: string;
	updatedAt: string;
}

export interface IUserContext {
	user: IUser | undefined;
	isLoggedIn: boolean;
	error: any;
	loading: boolean;
	saveUser: (newUser: IUser) => void;
	deleteUser: () => void;
}

export const userContext = createContext<any>(null);

const UserContextProvider: React.FC = ({children}) => {
	const [user, setUser]: [IUser | undefined, (user: IUser | undefined) => void] = useState<IUser>();
	const [isNewUser, setIsNewUser] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [error, setError] = useState<any>();
	const [loading, setLoading] = useState(true);

	const saveUser = useCallback((newUser: IUser) => {
		setUser(newUser);
		setIsNewUser(newUser.isNewUser);
		setIsLoggedIn(true);
		setError(undefined);
	}, []);

	const deleteUser = useCallback(() => {
		setUser(undefined);
		setIsLoggedIn(false);
		setError(undefined);
	}, []);

	const isAuth = useCallback(() => {
		setLoading(true);
		axiosInstance
			.get(`/auth/userDetails`)
			.then((response) => {
				if (response.status === 200) {
					setUser(response.data.user);
					setIsNewUser(response.data.user.isNewUser);
					setIsLoggedIn(true);
				} else {
					setError(response.data);
				}
				setLoading(false);
			})
			.catch((e) => {
				if (e.response) {
					setError(e.response);
				} else setError(e.message);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		isAuth();
	}, [isAuth]);

	return (
		<userContext.Provider
			value={{
				user,
				saveUser,
				deleteUser,
				isLoggedIn,
				error,
				loading,
				isNewUser,
			}}>
			{children}
		</userContext.Provider>
	);
};

export default UserContextProvider;
