import React, {useContext, useRef, useState, useEffect} from 'react';
import {Form, Input, Button, Select, Dropdown, Popup, Message} from 'semantic-ui-react';
import styles from './Register.module.css';

import {axiosInstance} from '../../../utils/axios';
import {Link, useHistory} from 'react-router-dom';
import {DomainsContext} from '../../../contexts/DomainsContext';
import {userContext} from '../../../contexts/UserContext';
import {toast} from '../../Toast/useToast';

const messageStyles = {
	success: {
		background: 'rgb(0 0 0 / 31%)',
		color: '#2ECC40',
		border: 'solid 1px #2ECC40',
	},
	error: {
		background: 'rgb(0 0 0 / 31%)',
		color: '#db2828',
		border: 'solid 1px #db2828',
	},
};

enum FetchDomainStatus {
	Loading,
	Success,
	Failure,
}

type IDomains = {
	key: number;
	text: string;
	value: string;
};
type IDomainsState = {
	status: FetchDomainStatus;
	domains: IDomains[];
};

type IRegistrtionDetails = {
	name: string;
	rollNumber: number;
	gender: string;
	email: string;
	contactNumber: string;
	github: string;
	domain: string[];
	interests: string;
	isLaptop: boolean;
};

type IErrors = {
	statusCode: number;
	type: 'success' | 'warning' | 'error' | '';
	heading: string;
	message: string | string[];
};

const RegisterForm = () => {
	const [userCreds, setUserCreds] = useState<IRegistrtionDetails>({
		name: '',
		rollNumber: 0,
		gender: '',
		email: '',
		contactNumber: '',
		github: '',
		domain: [],
		interests: '',
		isLaptop: true,
	});
	const formData = useRef(null);

	let history = useHistory();

	//initialize domains dropdown data, to []
	const domain: IDomainsState = {
		status: FetchDomainStatus.Loading,
		domains: [],
	};

	// fetching domains from DomainContext
	const {domains, error} = useContext(DomainsContext);
	const {saveUser} = useContext(userContext);

	if (error) {
		domain.status = 2;
	} else {
		domains.forEach((d: {id: number; name: string; shortName: string}) => {
			domain.domains.push({
				key: d.id,
				text: d.name,
				value: d.shortName,
			});
		});
		domain.status = FetchDomainStatus.Success;
	}

	const [errors, setErrors] = useState<IErrors>({
		heading: '',
		type: '',
		message: '',
		statusCode: 0,
	});

	// gender options
	const options = [
		{key: 'm', text: 'Male', value: 'male'},
		{key: 'f', text: 'Female', value: 'female'},
		// {key: 'o', text: 'Other', value: 'other'},
	];

	// document.addEventListener("wheel", function (event) {
	//   if (document?.activeElement?.type as string === "number") {
	//     (document.activeElement as Element).blur();
	//   }
	// });

	useEffect(() => {
		axiosInstance
			.get('/auth/userDetails')
			.then((resp) => {
				if (resp.status === 200) {
					const {contactNumber, rollNumber, email, gender, name, github, interests} = resp.data.user;
					setUserCreds((u) => {
						return {
							...u,
							rollNumber,
							contactNumber,
							email,
							gender,
							name,
							github,
							interests,
						};
					});
				}
			})
			.catch((err) => {
				if (err.response) {
					if (err.response.status === 401) {
						history.push('/auth/login');
					}
					// internal server error
					return setErrors({
						statusCode: 500,
						type: 'error',
						heading: 'Oops! Something went wrong. Try again later.',
						message: 'Something went wrong. Try again later.',
					});
				}
				if (err.message === 'Network Error')
					return setErrors({
						statusCode: 500,
						type: 'error',
						heading: 'Unable to connect to server',
						message: 'Check your connection or try again later.',
					});
				return setErrors({
					statusCode: 500,
					type: 'error',
					heading: 'Something went wrong.',
					message: 'Something went wrong. Error: ' + err.message,
				});
			});
	}, [history]);

	const handleSubmit = async () => {
		process.env.NODE_ENV === 'development' && console.log('Form data : ', userCreds);

		handleResetErrors();

		let validationErrors = false;

		const messages: string[] = [];

		// domain validation errors
		if (userCreds.domain.length === 0) {
			validationErrors = true;
			messages.push('Please select atleast 1 domain.');
		}

		// no gender selected
		if (!userCreds.gender) {
			validationErrors = true;
			messages.push('Gender is not selected');
		}

		//interests fields should be within 255 characters
		if (userCreds.interests.length >= 255) {
			validationErrors = true;
			messages.push('Interests should be less than 255 characters');
		}

		if (validationErrors) {
			return setErrors({
				statusCode: 400,
				type: 'error',
				heading: 'Check your inputs',
				message: messages,
			});
		}

		// data has been checked,
		// fix type bug and send req to server
		userCreds.rollNumber = Number(userCreds.rollNumber);

		try {
			const resp = await axiosInstance.post('/auth/register', {
				...userCreds,
			});
			toast.dark('You have successfully registered for the inductions, Login with your credentials');
			saveUser(resp.data.user);
			history.push('/dashboard');
		} catch (err: any) {
			if (err.response) {
				// bad data
				if (err.response.status === 400) {
					return setErrors({
						statusCode: 400,
						type: 'error',
						heading: 'The following fields have invalid data.',
						message: err.response.data.errors ? err.response.data.errors : err.response.data.message,
					});
				}
				// registrations are over
				else if (err.response.status === 403) {
					return setErrors({
						statusCode: 403,
						type: 'error',
						heading: 'Registrations are closed',
						message: 'The deadline for registering is up.',
					});
				}
				// user already exists
				else if (err.response.status === 401) {
					return setErrors({
						statusCode: 401,
						type: 'error',
						heading: 'User already exists',
						message: 'A user with the given credentials already exists.',
					});
				}
				// internal server error
				return setErrors({
					statusCode: 500,
					type: 'error',
					heading: 'Oops! Something went wrong. Try again later.',
					message: 'Something went wrong. Try again later.',
				});
			}
			if (err.message === 'Network Error')
				return setErrors({
					statusCode: 500,
					type: 'error',
					heading: 'Unable to connect to server',
					message: 'Check your connection or try again later.',
				});
			return setErrors({
				statusCode: 500,
				type: 'error',
				heading: 'Something went wrong.',
				message: 'Something went wrong. Error: ' + err.message,
			});
		}
	};

	const handleResetErrors = () => {
		setErrors({
			statusCode: 0,
			type: '',
			heading: '',
			message: [],
		});
	};

	return (
		<React.Fragment>
			<Form className={styles.registerForm} onSubmit={handleSubmit} ref={formData}>
				{' '}
				<Form.Field textAlign="left">
					<label>Name :</label>
					<Input
						placeholder="Enter your full name"
						type="text"
						icon="book"
						onChange={(e) => setUserCreds({...userCreds, name: e.target.value})}
						value={userCreds.name}
						required
					/>
				</Form.Field>
				<Form.Field textAlign="left">
					<label>Roll Number :</label>
					<Input
						placeholder="Enter your Roll number"
						type="number"
						icon="user"
						onChange={(e) => setUserCreds({...userCreds, rollNumber: Number(e.target.value)})}
						onWheel={(e: any) => e.target.blur()}
						value={userCreds.rollNumber}
						disabled
						required
					/>
				</Form.Field>
				<Form.Field
					control={Select}
					label="Gender :"
					options={options}
					placeholder="Gender"
					onChange={(e: any, value: any) => setUserCreds({...userCreds, gender: value.value})}
					value={userCreds.gender}
					required
				/>
				<Form.Field textAlign="left">
					<label>Email :</label>
					<Input
						placeholder="Enter your email"
						type="email"
						icon="envelope"
						onChange={(e) => setUserCreds({...userCreds, email: e.target.value})}
						value={userCreds.email}
						required
					/>
					<p
						style={{
							fontSize: '0.9rem',
							transform: 'translate(0px, 1rem)',
						}}>
						Provide a email which is you are the most accessible by.
					</p>
				</Form.Field>
				<Form.Field textAlign="left">
					<label>Contact Number :</label>
					<Input
						placeholder="Enter your contact number along with country code."
						type="tel"
						icon="address book"
						onChange={(e) =>
							setUserCreds({
								...userCreds,
								contactNumber: e.target.value,
							})
						}
						onWheel={(e: any) => e.target.blur()}
						value={userCreds.contactNumber}
						required
					/>
					<p
						style={{
							fontSize: '0.9rem',
							transform: 'translate(0px, 1rem)',
						}}>
						Enter your phone number along with country code. Eg. +91 63804 24418
					</p>
				</Form.Field>
				<Form.Field textAlign="left">
					<label>Github Username :</label>
					<Input
						placeholder="Enter your Github Username"
						type="text"
						icon="github"
						onChange={(e) => setUserCreds({...userCreds, github: e.target.value})}
						value={userCreds.github}
						required
					/>
					<p style={{marginTop: '1rem', fontSize: '0.9rem'}}>
						If you do not have a Github account, please create one{' '}
						<a href="http://github.com/join" target="_blank" rel="noopener noreferrer">
							here.
						</a>
						<br />
						Note : Please provide your Github{' '}
						<strong>
							<u>Username</u>
						</strong>{' '}
						and not the email address you used to sign up for Github.
					</p>
				</Form.Field>
				<Form.Field textAlign="left">
					{domain.status === 1 ? (
						<Popup
							trigger={<label style={{width: 'fit-content'}}>Domains :</label>}
							position="right center"
							content={
								<p>
									To know more about each sub-domain, go to <Link to="/auth/info">here</Link>.
								</p>
							}
							hoverable
							flowing
							offset={[0, 20]}
						/>
					) : (
						<label>Domains :</label>
					)}
					{domain.status === 0 ? (
						<div className={styles.loader} />
					) : domain.status === 1 ? (
						<React.Fragment>
							<Dropdown
								placeholder="Domains"
								multiple
								selection
								options={domain.domains}
								onChange={(e: any, value: any) => setUserCreds({...userCreds, domain: value.value})}
								required
							/>
							<p
								style={{
									fontSize: '0.9rem',
									transform: 'translate(0px, 1rem)',
								}}>
								Select all the domains you are interested in.
							</p>
						</React.Fragment>
					) : (
						<p
							style={{
								paddingBottom: '0',
								marginBottom: '1.5rem',
								color: '#ce2a1f',
								fontSize: '1.2rem',
							}}>
							<strong>Something went wrong. Try again later.</strong>
						</p>
					)}
				</Form.Field>
				<Form.TextArea
					label="Interests :"
					placeholder="Tell us more about your interests and stuff"
					onChange={(e) => setUserCreds({...userCreds, interests: e.target.value})}
					value={userCreds.interests}
					required
				/>
				<p style={{fontSize: '0.9rem', transform: 'translate(0px, -20px)'}}>
					Let us know about any other fields of interest you may have with respect to programming (This
					information will be used to assign a like-minded mentor). If you have multiple interests, please
					separate them with commas. Eg: machine learning, functional programming
				</p>
				<Message
					{...(errors.type === 'success' ? 'positive' : 'negative')}
					hidden={!errors.statusCode}
					style={errors.type === 'success' ? messageStyles.success : messageStyles.error}
					onDismiss={handleResetErrors}>
					<Message.Header
						content={errors.heading}
						styles={{
							marginBottom: '1rem',
						}}
					/>
					{Array.isArray(errors.message) ? (
						<Message.List>
							{errors.message.map((str) => (
								<Message.Item>{str}</Message.Item>
							))}
						</Message.List>
					) : (
						errors.message
					)}
				</Message>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
						alignItems: 'center',
					}}>
					<Button
						type="submit"
						inverted
						color="green"
						size="large"
						style={{marginRight: '2rem'}}
						content="Register"
						disabled={domain.status === 2}
					/>
					<Button as={Link} inverted primary to="/auth/info" content="Go Back" size="large" />
				</div>
			</Form>
		</React.Fragment>
	);
};

export {RegisterForm};
