import React from 'react';
import {Dimmer} from 'semantic-ui-react';
import logo from '../../images/deltaLogoGreen.png';
import styles from './style.module.css';

// copied from dwoc :)
const Spinner: React.FC = () => {
	return (
		<Dimmer active>
			<div className={styles.center}>
				<SpinningLogo />
			</div>
		</Dimmer>
	);
};

export const SpinningLogo = (style?: any) => <img className={styles.img} src={logo} alt="logo" style={style} />;
export default Spinner;
