import React, {createContext, useEffect, useState, useCallback} from 'react';
import {axiosInstance} from '../utils/axios';

export interface IConstant {
	id: number;
	key: string;
	value: string;
}

export const ConstantContext = createContext<any>(null);

const ConstantContextProvider: React.FC = ({children}) => {
	const [constants, setConstants]: [IConstant[], (constants: IConstant[]) => void] = useState<IConstant[]>([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	const getConstants = useCallback(() => {
		axiosInstance
			.get<IConstant[]>(`/constants`)
			.then((response) => {
				setConstants(response.data);
				setLoading(false);
			})
			.catch((e) => {
				if (e.response) setError(e.response);
				else setError(e.message);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		getConstants();
	}, [getConstants]);

	return <ConstantContext.Provider value={{constants, error, loading}}>{children}</ConstantContext.Provider>;
};

export default ConstantContextProvider;
