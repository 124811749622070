import React from 'react';
import styles from './WhyDeltaPage.module.css';
import whydeltapic from '../../../images/whydelta.jpeg';

const WhyDeltaPage = () => {
	return (
		<div className={styles.whydeltapage}>
			<div className={styles.whydeltaheading}>Why Delta?</div>
			<div className={styles.whydeltacontainer}>
				<div className={styles.deltacarousel}>
					<img src={whydeltapic} alt=""></img>
				</div>
				<div className={styles.content}>
					Delta Force is NIT Trichy's official programming club. We are a bunch of tech enthusiasts who take
					up interesting projects to explore the latest technologies. Delta Force is tasked with maintaining
					major institute portals and Fest websites. Join us to equip yourself with the best and newest of
					technical skills and enter the world of programming.
				</div>
			</div>
		</div>
	);
};

export default WhyDeltaPage;
