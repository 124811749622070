import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {toast} from 'react-toastify';
import {Button, Container, Form, Header} from 'semantic-ui-react';
import {IUser, IUserContext, userContext} from '../../contexts/UserContext';
import {axiosInstance} from '../../utils/axios';
import Spinner from '../Spinner';
import styles from './Profile.module.css';

type UpdatedDetails = {
	name: string;
	email: string;
	github: string;
	rollNumber: string;
	contactNumber: string;
	interests: string;
};

const EditProfile = () => {
	const {user, isLoggedIn, loading, saveUser} = useContext(userContext) as IUserContext;
	const history = useHistory();

	const [userDetails, updateUserDetails] = useState<UpdatedDetails>({
		name: user?.name || '',
		email: user?.email || '',
		github: user?.github || '',
		rollNumber: user?.rollNumber || '',
		contactNumber: user?.contactNumber || '',
		interests: user?.interests || '',
	});

	useEffect(() => {
		updateUserDetails({
			name: user?.name || '',
			email: user?.email || '',
			github: user?.github || '',
			rollNumber: user?.rollNumber || '',
			contactNumber: user?.contactNumber || '',
			interests: user?.interests || '',
		});
	}, [user]);

	const onSubmit = async () => {
		process.env.NODE_ENV === 'development' && console.log(userDetails);

		if (userDetails.interests.length >= 255) {
			return toast.error('Interests should be maximum 255 characters long');
		}

		try {
			await axiosInstance.post('/profile/edit', userDetails);

			toast.success('Successfully updated your profile.');
			saveUser({...(user as IUser), ...userDetails});
		} catch (err: any) {
			if (err.response) {
				console.log(err.response);
				if (err.response.status === 400) {
					if (err.response.data.message === 'Bad request') {
						toast.error('Some of your fields are invalid');
						return err.response.data.errors.forEach((e: string) => toast.warn(e + ' is invalid'));
					}
					return toast.error(err.response.data.error);
				}
				// handling 401, just in case
				if (err.response.status === 401) return history.push('/auth/login');
				return toast.error('Something went wrong, try again later.');
			}
			if (err?.message === 'Network Error')
				return toast.error('Check your internet connection, or try again later.');
			toast.error('Something went wrong, try again later. Error: ' + err);
			toast.error('If the error persists contact the event administrator.');
		}
	};

	if (!isLoggedIn) history.push('/auth/login');
	if (loading) return <Spinner />;

	return (
		<div className={styles.flexBox} style={{maxWidth: '920px', margin: ' auto'}}>
			<Container className={styles.profileContainer}>
				<Header as="h1" content="Edit Profile" size="huge" />

				<Form className={styles.profileForm} onSubmit={onSubmit}>
					<Form.Field>
						<label>Profile Picture:</label>
						<p>
							You can edit you profile picture by updating the{' '}
							<a href="https://en.gravatar.com/" target="_blank" rel="noopener noreferrer">
								Gravatar
							</a>{' '}
							associated with your registered email.
						</p>
					</Form.Field>
					<Form.Field>
						<label>Name : </label>
						<Form.Input
							type="text"
							placeholder="Enter your name"
							value={userDetails.name}
							onChange={(e) => updateUserDetails((prev) => ({...prev, name: e.target.value}))}
							required
						/>
					</Form.Field>
					<Form.Field>
						<label>Roll Number: </label>
						<Form.Input
							type="number"
							value={userDetails.rollNumber}
							placeholder="Enter your rollno"
							onWheel={(e: any) => e.target.blur()}
							required
							disabled
						/>
					</Form.Field>{' '}
					<Form.Field>
						<label>Email: </label>
						<Form.Input
							type="email"
							placeholder="Enter your email"
							value={userDetails.email}
							onChange={(e) =>
								updateUserDetails((prev) => ({
									...prev,
									email: e.target.value,
								}))
							}
							required
						/>
					</Form.Field>{' '}
					<Form.Field>
						<label>Contact Number: </label>
						<Form.Input
							type="tel"
							placeholder="Enter your contact number"
							value={userDetails.contactNumber}
							onChange={(e) =>
								updateUserDetails((prev) => ({
									...prev,
									contactNumber: e.target.value,
								}))
							}
							required
						/>
						<p
							style={{
								fontSize: '0.9rem',
								// transform: "translate(0, 1rem)",
							}}>
							Enter your phone number along with country code. Eg. +91 63804 24419
						</p>
					</Form.Field>{' '}
					<Form.Field>
						<label>Github Username: </label>
						<Form.Input
							type="text"
							placeholder="Enter your Github username"
							value={userDetails.github}
							onChange={(e) =>
								updateUserDetails((prev) => ({
									...prev,
									github: e.target.value,
								}))
							}
							required
						/>
					</Form.Field>
					<Form.TextArea
						label="Interests :"
						placeholder="Tell us more about your interests and stuff"
						value={userDetails.interests}
						onChange={(e) => {
							updateUserDetails((prev) => ({
								...prev,
								interests: e.target.value,
							}));
						}}
						required
					/>
					<Button type="submit" inverted color="green" content="Update" size="large" />
					<Button
						inverted
						primary
						content="Go Back"
						size="large"
						floated="right"
						type="button"
						onClick={() => window.history.back()}
					/>
				</Form>
			</Container>
		</div>
	);
};

export {EditProfile};
