import React, {useEffect, useRef, useState} from 'react';
import {Icon, Popup, SemanticCOLORS} from 'semantic-ui-react';
import {IUser} from '../../contexts/UserContext';
import {axiosInstance} from '../../utils/axios';
import {ISubmission, ISubmissionWithDomain} from '../SubmissionCard/submissionsInterfaces';
import styles from './usercard.module.css';

export const UserCard = (props: any) => {
	const user: IUser = props.user;
	const domain: string = props.domain;

	const [loading, setLoading] = useState(true);
	const progresses = useRef<string[][]>([]);

	const [isPopupDisabled, setPopupDisabled] = useState(true);
	const interestsSpan = useRef<HTMLSpanElement>(null);

	const isEllipsisActive = (e: HTMLElement) => e.offsetWidth < e.scrollWidth;

	useEffect(() => {
		axiosInstance
			.get(`/dashboard/${user.id}`)
			.then((res) => {
				if (res.status === 200) {
					res.data.submissions.forEach((sdomain: ISubmissionWithDomain) => {
						if (sdomain.shortName === domain) {
							sdomain.submissions.forEach(
								(submission: ISubmission) =>
									submission.reviewed !== 'NOT_REVIEWED' &&
									progresses.current.push(
										submission.reviewed === 'PARTIAL'
											? ['brown', 'Partial']
											: submission.reviewed === 'NORMAL'
											? ['grey', 'Normal']
											: ['yellow', 'Hacker']
									)
							);
						}
					});
					setLoading(false);
				} else {
					progresses.current.push(['error']);
					setLoading(false);
				}
			})
			.catch((err) => {
				progresses.current.push(['error']);
				setLoading(false);
			});
	}, [user, domain]);

	useEffect(() => {
		setPopupDisabled(interestsSpan.current ? !isEllipsisActive(interestsSpan.current) : true);
	}, [interestsSpan]);

	return (
		<div className={styles.card}>
			<div className={styles.image}>
				<img
					className={styles.identicon}
					src={`https://gravatar.com/avatar/${user.hashedEmail}.jpg?d=retro`}
					alt="Error loading identicon :("
				/>
				<div
					style={{
						width: '140px',
						background: '#28A315',
						color: '#ffffff',
						textAlign: 'center',
					}}>
					<b>{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</b>
				</div>
			</div>
			<div className={styles.info}>
				<div className={styles.header}>
					<h3>{user.name}</h3>
					<h5 style={{marginTop: '1px'}}>{user.rollNumber}</h5>
					{user.role !== 'mentee' && (
						<Icon
							style={{margin: '0 0 8px 0'}}
							name={domain === 'web' ? 'computer' : domain === 'app' ? 'mobile alternate' : 'terminal'}
						/>
					)}
					{!loading &&
						progresses.current.length !== 0 &&
						user.role === 'mentee' &&
						progresses.current[0][0] === 'error' && <span>Error</span>}
					{!loading &&
						progresses.current.length !== 0 &&
						!(progresses.current[0][0] === 'error') &&
						user.role === 'mentee' && (
							<div className={styles.taskProgress}>
								{progresses.current.map(([color, status], i) => (
									<Popup
										key={i}
										inverted
										on={['hover', 'click']}
										position="top center"
										content={status}
										trigger={<Icon name="star" size="large" color={color as SemanticCOLORS} />}
									/>
								))}
							</div>
						)}
				</div>
				<div className={styles.extra}>
					<div style={{display: 'flex'}}>
						<a href={`/profile/${user.id}`}>Profile</a>
						<span style={{margin: '0 8px'}} color="white">
							·
						</span>
						<a href={`https://github.com/${user.github}`} target="_blank" rel="noopener noreferrer">
							Github
						</a>
					</div>
					<Popup
						inverted
						wide={window.innerWidth > 767}
						offset={[0, -10]}
						position="bottom center"
						on={['hover', 'click']}
						content={user.interests}
						disabled={isPopupDisabled}
						trigger={
							<div
								style={{
									marginTop: '8px',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}>
								<span>Interests:</span>
								<span ref={interestsSpan} className={styles.interests}>
									{user.interests}
								</span>
							</div>
						}
					/>
					<span>{`Contact: ${user.contactNumber}`}</span>
				</div>
			</div>
		</div>
	);
};
