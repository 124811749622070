import React from 'react';
import styles from './DomainsPage.module.css';

import {
	motion,
	//@ts-ignore
} from 'framer-motion/dist/framer-motion';

import {domains} from './Domains';

const LazyShow: React.FC<{children: React.ReactNode; turn?: string}> = ({children, turn}) => {
	return (
		<motion.div
			className={styles.domaincontainer}
			initial={{opacity: 0}}
			whileInView={{
				opacity: 1,
				transition: {ease: 'easeOut', duration: 0.5},
			}}
			whileHover={{
				scale: 1.1,
				rotate: turn === 'left' ? -5 : 5,
				transition: {duration: 0.3},
			}}>
			{children}
		</motion.div>
	);
};

const DomainsPage = () => {
	return (
		<div className={styles.domainspage}>
			<div className={styles.domainheading}>Domains</div>
			<div className={styles.domains}>
				{domains.map((domain) => {
					return (
						<LazyShow>
							<div className={styles.domaintitle}>{domain.title}</div>
							<div className={styles.domainimage}>
								<img src={domain.image} style={{objectFit: 'contain'}} alt={domain.title}></img>
							</div>
							<div className={styles.domaincontent}>{domain.content}</div>
						</LazyShow>
					);
				})}
			</div>
		</div>
	);
};

export default DomainsPage;
