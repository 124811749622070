import aor from "../../../images/aor.png";
import dauth from "../../../images/dauth.png";
import cc from "../../../images/cc.png";
import arcadia from "../../../images/arcadia.png";
import timeWarp from "../../../images/timeWarp.png"
import arhunt from "../../../images/arhunt.png"
import outbreak from "../../../images/outbreak.jpg"
export interface Project {
  name: string;
  description: string;
  image: string;
}

export const projects: Project[] = [
  {
    name: "Arcadia",
    description:
      "Arcadia: Rivals, a strategy-based game where you collect powerful mini-conquerors (or minicons) and challenge opponents. Explore the maps, collect minicons with unique abilities, place them strategically on your lineup and engage in epic one-on-one battles to gain as many trophies as possible to conquer the world of Arcadia.",
    image: arcadia,
  },
  {
    name: "Attack On Robots",
    description:
      "Attack on Robots is a multiplayer resource management game based on strategic city planning. Participants attempt to design safe and secure cities with the available resources and attempt to find weaknesses in other cities. Each player designs a secure city for the robots using the given buildings and defenses.",
    image: aor,
  },
  {
    name: "Code Character",
    description:
      "Code Character is a strategy-based programming game wherein you control troops in turns using code written in one of the multiple programming languages (C++, Python, Java) available in the game. The objective is to destroy and conquer the opponent's territory by creating and writing strategic defences in code via the in-game map",
    image: cc,
  },
  {
    name: "DAuth",
    description:
      "DAuth is an OAuth2.0-based SSO (Single Sign On) for NITT students. It serves to replace existing webmail-based login and redundant registration. DAuth lets the application developers securely get access to users’ data without users having to share their passwords.It is more secure and easier to integrate with your services and use.",
    image: dauth,
  },
  {
    name: "Time Warp",
    description:
      "Timewarp is a single-player game wherein you solve puzzles after finding clues by interacting with the map and using your code editor to code your way to the next level. The game's objective is to solve as many puzzles as possible before the event runs out.",
    image: timeWarp,
  },
  {
    name: "Outbreak Origins",
    description:
      "Outbreak Origins is a game which draws inspiration from the Plague Inc. franchise .  To save the lands Xyrus, Glaciallus and Pyros, players have to devise a game plan and strategically combat the devious virus to contain the proliferation of the virus before it plunges these regions into chaos.",
    image: outbreak,
  },
  {
    name: "AR Hunt",
    description:
      "AR Hunt is an Augmented Reality based Android treasure hunt game that enables freshers to explore the campus by solving clues and crossing a variable set of checkpoints to discover AR objects within a time constraint.",
    image: arhunt,
  },
];
