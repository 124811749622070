class Point {
  x: number;
  y: number;
  charArr: string[];
  fontSize: number;
  ctx: CanvasRenderingContext2D;
  ctx2: CanvasRenderingContext2D;
  ch: number;
  value?: string;
  speed?: number;

  constructor(
    x: number,
    y: number,
    charArr: string[],
    ctx: CanvasRenderingContext2D,
    ctx2: CanvasRenderingContext2D,
    fontSize: number,
    ch: number
  ) {
    this.x = x;
    this.y = y;
    this.charArr = charArr;
    this.ctx = ctx;
    this.ctx2 = ctx2;
    this.fontSize = fontSize;
    this.ch = ch;
  }
  randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  randomFloat(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  draw(ctx: CanvasRenderingContext2D) {
    this.value = this.charArr[
      this.randomInt(0, this.charArr.length - 0.01)
    ].toUpperCase();
    this.speed = this.randomFloat(1, 7);

    this.ctx2.fillStyle = "rgba(255,255,255,0.8)";
    this.ctx2.font = this.fontSize + "px san-serif";
    this.ctx2.fillText(this.value, this.x, this.y);

    ctx.fillStyle = "#0F0";
    ctx.font = this.fontSize + "px san-serif";
    ctx.fillText(this.value, this.x, this.y);

    this.y += this.speed;
    if (this.y > this.ch) {
      this.y = this.randomFloat(-100, 0);
      this.speed = this.randomFloat(2, 7);
    }
  }
}

export default class Matrix {
  ctx: CanvasRenderingContext2D;
  ctx2: CanvasRenderingContext2D;
  cw: number;
  ch: number;
  charArr: string[];
  fallingCharArr: Point[];
  fontSize: number;
  maxColums: number;
  runAnimation: boolean;

  constructor() {
    let canvas = document.getElementById("canvas") as HTMLCanvasElement;
    this.ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    let canvas2 = document.getElementById("canvas2") as HTMLCanvasElement;
    this.ctx2 = canvas2.getContext("2d") as CanvasRenderingContext2D;
    // full screen dimensions
    this.cw = window.innerWidth;
    this.ch = window.innerHeight;
    this.runAnimation = true;
    this.charArr = ["1", "0"];
    // maxCharCount = 100,
    this.fallingCharArr = [];
    this.fontSize = 15;
    this.maxColums = this.cw / this.fontSize;
    canvas.width = canvas2.width = this.cw;
    canvas.height = canvas2.height = this.ch;
    for (let i = 0; i < this.maxColums; i++) {
      this.fallingCharArr.push(
        new Point(
          i * this.fontSize,
          this.randomFloat(-1000, 0),
          this.charArr,
          this.ctx,
          this.ctx2,
          this.fontSize,
          this.ch
        )
      );
    }
  }

  randomFloat(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  update(): any {
    this.ctx.fillStyle = "rgba(0,0,0,0.03)";
    this.ctx.fillRect(0, 0, this.cw, this.ch);

    this.ctx2.clearRect(0, 0, this.cw, this.ch);

    var i = this.fallingCharArr.length;
    while (i--) {
      this.fallingCharArr[i].draw(this.ctx);
      // var v = fallingCharArr[i];
    }

    if (!this.runAnimation) {
      this.ctx2.clearRect(0, 0, this.cw, this.ch);
      this.ctx.clearRect(0, 0, this.cw, this.ch);
      return;
    }
    // optimization
    return setTimeout(() => requestAnimationFrame(this.update.bind(this)), 0);
  }

  stop() {
    this.runAnimation = false;
  }

  // updateFunction = update;
  start() {
    setTimeout(() => requestAnimationFrame(this.update.bind(this)), 0);
  }
}
