import styles from './PageNotFound.module.css';
import React, {useContext, useEffect, useRef} from 'react';
import {userContext} from '../../contexts/UserContext';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const PageNotFound = () => {
	const {isLoggedIn} = useContext(userContext);
	const Route = useRef('');

	useEffect(() => {
		if (isLoggedIn) {
			Route.current = '/dashboard';
		} else {
			Route.current = '/auth/login';
		}
	}, [isLoggedIn]);

	return (
		<>
			<div className={styles.cont}>
				<text className={styles.error}>404</text>
				<br />
				<span className={styles.info}>Page not found</span>
				<Button inverted as={Link} to={Route.current} color="green" link className={styles.returnBtn}>
					Return
				</Button>
			</div>
		</>
	);
};

export default PageNotFound;
