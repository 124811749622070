import React, {useContext, useRef, useState} from 'react';
import {Button, Dropdown, Form, TextArea} from 'semantic-ui-react';
import {userContext} from '../../contexts/UserContext';
import {axiosInstance} from '../../utils/axios';
import {toast} from '../Toast/useToast';
import styles from './SubmissionCard.module.css';
import {ISubmission, ITask} from './submissionsInterfaces';

interface IProps {
	submission: ISubmission;
	task: ITask;
	printSubmissions: () => void;
	setSubmissionReviewStatus: (submission: ISubmission) => void;
}

const options = [
	{key: 1, value: 'PARTIAL', text: 'Partial'},
	{key: 2, value: 'NORMAL', text: 'Normal'},
	{key: 3, value: 'HACKER', text: 'Hacker'},
];

export const MentorSubmissionCard = ({submission, task, printSubmissions, setSubmissionReviewStatus}: IProps) => {
	const {user, saveUser} = useContext(userContext);
	const statusRef = useRef<HTMLSpanElement>(null);
	const [reviewed, setReviewed] = useState(submission.reviewed !== 'NOT_REVIEWED');

	let [comments, setComment] = useState(submission.comments ? submission.comments : '');
	let [completionStatus, setCompletionStatus] = useState('');

	const handleReview = () => {
		if (comments.length < 10)
			return toast.error(`'${comments}' is not very useful comment. Enter a longer comment.`);
		if (!completionStatus) return toast.error('A review without completion status is not very helpful.');

		axiosInstance
			.post('/dashboard/reviewSubmission', {
				submissionId: submission.id,
				reviewStatus: completionStatus,
				comments: comments,
			})
			.then((res) => {
				console.log(res);
				printSubmissions();
				if (res.status === 200) {
					statusRef.current?.style.setProperty('color', 'green');
					setReviewed(true);
					saveUser({...user, foo: 'bar'}); //force re-render sidebar to update reviewed stat.
					// updating submission state, to re-render the page
					setSubmissionReviewStatus({
						...submission,
						comments,
						reviewed: completionStatus,
					});
					toast.dark('Review submitted successfully!');
				} else {
					toast.error('Something went wrong! Please try again later');
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.response.message);
			});
	};

	return (
		<div className={styles.card}>
			<div className={styles.cardHeader}>
				<h3 style={{marginTop: '0px', marginBottom: '16px'}}>Mentee: {submission.user.name}</h3>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`https://github.com/${submission.user.github}/${submission.githubUrl}`}>
					Github repo
				</a>
			</div>
			<Form style={{margin: '16px'}}>
				<TextArea
					rows="2"
					placeholder="Comments..."
					onChange={(e, data) => setComment(String(data.value))}
					value={comments}
				/>
			</Form>
			<div style={window.innerWidth > 768 ? {display: 'flex', alignItems: 'center'} : {}}>
				<Dropdown
					upward
					style={{margin: '0 16px', minWidth: '170px'}}
					placeholder="Completion Status"
					options={options}
					value={submission.reviewed}
					selection
					item
					onChange={(e, data) => setCompletionStatus(String(data.value))}
				/>
				<Button
					subid={submission.id}
					style={{
						marginLeft: '16px',
						marginTop: window.innerWidth > 768 ? '0px' : '16px',
					}}
					inverted
					color="green"
					content={reviewed ? 'Update Review' : 'Submit Review'}
					onClick={handleReview}
				/>
			</div>
			<div style={{marginTop: '1rem'}}>
				<span ref={statusRef} style={{color: reviewed ? 'green' : 'red'}}>
					Status: {reviewed ? 'Reviewed' : 'Not Reviewed'}
				</span>
			</div>
		</div>
	);
};
