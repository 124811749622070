import React, {useContext, useEffect} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {userContext} from '../../contexts/UserContext';
import {Login} from './Login/login';
import {Information} from './Register/Information';
import {Register} from './Register/Register';
import Matrix from '../../utils/matrix';
import {DAuthCallBack} from './Login/DauthCallback';

const AuthContainer = () => {
	const {isLoggedIn, isNewUser} = useContext(userContext);

	useEffect(() => {
		const matrix = new Matrix();
		matrix.start();
		return () => {
			setTimeout(() => {
				matrix.stop();
			}, 0);
			return;
		};
	}, []);

	return (
		<div
			style={{
				height: '100vh',
				// position: "relative",
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<Switch>
				{isLoggedIn && !isNewUser && <Redirect from="/auth" to="/dashboard" />}
				<Route path="/auth/login" exact>
					<Login />
				</Route>
				<Route path="/auth/callback" exact>
					<DAuthCallBack />
				</Route>
				<Route path="/auth/info" exact>
					<Information />
				</Route>
				<Route path="/auth/register" exact>
					<Register />
				</Route>
			</Switch>
		</div>
	);
};

export {AuthContainer};
