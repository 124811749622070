import React, {useContext, useEffect, useState} from 'react';
import {Button, Divider, Modal, Radio, TransitionablePortal} from 'semantic-ui-react';
import Spinner from '../../../components/Spinner';
import {toast} from '../../../components/Toast/useToast';
import {userContext} from '../../../contexts/UserContext';
import {axiosInstance} from '../../../utils/axios';
import styles from './changeDomains.module.css';

export const ChangeDomains = () => {
	const [webDev, setWebDev] = useState(false);
	const [appDev, setAppDev] = useState(false);
	const [sysAd, setSysAd] = useState(false);
	const [open, setOpen] = useState(false);
	const [content, setContent] = useState('');
	const [selected, setSelected] = useState<any>([]);

	const [loading, setLoading] = useState(true);

	const {user, saveUser} = useContext(userContext);

	useEffect(() => {
		const fetchDashboard = async () => await axiosInstance.get(`/dashboard/${user.id}`);
		fetchDashboard()
			.then((res) => {
				const domains: string[] = res.data.userDomains.map((domain: any) => domain.shortName);
				domains.forEach((domain) => {
					if (domain === 'web') setWebDev(true);
					if (domain === 'app') setAppDev(true);
					if (domain === 'sysad') setSysAd(true);
				});
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}, [user.id]);

	const changeDomain = async (domain: string, isOptOut: boolean) => {
		const domainShortName = domain === 'WebDev' ? 'web' : domain === 'AppDev' ? 'app' : 'sysad';
		return await axiosInstance.post(`/domain/${isOptOut ? 'remove' : 'add'}`, {
			domain: domainShortName,
		});
	};

	const onRadioToggle = (e: any, data: any) => {
		setContent(`You are about to opt ${!data.checked ? 'out of' : 'in to'} the ${data.name} domain. Are you sure?`);
		setSelected([data.name, !data.checked]);
		setOpen(true);
	};

	const handleConfirm = () => {
		setLoading(true);
		const domain = selected[0];
		const isOptOut = selected[1];
		changeDomain(domain, isOptOut)
			.then((res) => {
				if (res.status === 200) {
					domain === 'WebDev'
						? setWebDev(!isOptOut)
						: domain === 'AppDev'
						? setAppDev(!isOptOut)
						: setSysAd(!isOptOut);
					toast.dark('Domain change successful!');
					setLoading(false);
					saveUser({...user, dummy: 'dummy'}); //hacky way to force sidebar re-render :p
				} else {
					toast.error('Some Error occured!');
					setLoading(false);
				}
			})
			.catch((err) => {
				toast.error(err.response.data.message);
				setLoading(false);
			});
	};

	if (loading) return <Spinner />;

	return (
		<div>
			<h1>Your Domains</h1>
			<Divider inverted />
			<div>
				<div className={styles.radio}>
					<Radio
						style={{marginRight: '1.5rem'}}
						toggle
						name="WebDev"
						checked={webDev}
						onChange={onRadioToggle}
					/>
					WebDev
				</div>
				<div className={styles.radio}>
					<Radio
						style={{marginRight: '1.5rem'}}
						toggle
						name="AppDev"
						checked={appDev}
						onChange={onRadioToggle}
					/>
					AppDev
				</div>
				<div className={styles.radio}>
					<Radio
						style={{marginRight: '1.5rem'}}
						toggle
						name="SysAd"
						checked={sysAd}
						onChange={onRadioToggle}
					/>
					SysAd
				</div>
			</div>
			<Divider hidden />
			{user.role === 'mentee' && (
				<p>It is recommended that you consult with your mentor before opting in or out of a domain.</p>
			)}
			<TransitionablePortal open={open}>
				<Modal open onClose={() => setOpen(false)}>
					<Modal.Header style={{background: '#ff0000', color: 'white'}} content="Caution!" />
					<Modal.Content style={{background: '#444444', color: 'white'}} content={content} />
					<Modal.Actions style={{background: '#444444', color: 'white'}}>
						<Button secondary onClick={() => setOpen(false)} content="No" />
						<Button
							primary
							content="Yes"
							onClick={() => {
								handleConfirm();
								setOpen(false);
							}}
						/>
					</Modal.Actions>
				</Modal>
			</TransitionablePortal>
		</div>
	);
};
