import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Container, Header, Image} from 'semantic-ui-react';
// import {registerForm} from './registerForm';
// import styles from "./register.module.css";
import styles from './Register.module.css';
import {InductionInfo} from './InductionInfo';
// import { RegisterForm } from "./RegisterForm";
import deltaIcon from '../../../images/deltaLogoGreen.png';

const Information = () => {
	return (
		<Container text className={styles.registerContainer} style={{maxWidth: '650px !important'}}>
			<Header as="h1" size="huge" style={{color: '#f1f1f1', zoom: '1.3', paddingLeft: '8px'}}>
				Welcome
				<Image src={deltaIcon} floated="right" className={styles.sexyAnimation} />
			</Header>
			<br />
			<InductionInfo />
			<Button as={Link} to="/auth/register" primary inverted>
				Register
			</Button>
			<br />
			<br />
			<Link to="/auth/login" style={{maxWidth: '70%', display: 'inline-block'}}>
				Already have an account ? Login
			</Link>
			<Link to="/" style={{float: 'right', marginRight: '1rem'}}>
				Return
			</Link>
		</Container>
	);
};

export {Information};
