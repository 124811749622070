import React, {useState, useContext, useRef, useLayoutEffect} from 'react';
import {Redirect} from 'react-router';
import {Icon} from 'semantic-ui-react';
import Spinner from '../../components/Spinner';
import {userContext} from '../../contexts/UserContext';
import {CustomSidebar} from '../sidebar';
import {Announcements} from './announcements';
import {ChangeDomains} from './changeDomains';
import styles from './dashboard.module.css';
import {MentorsMentees} from './MentorsMentees';
import {Submissions} from './submissions';
import {Tasks} from './tasks';

const useWindowSize = () => {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size[0];
};

export const Dashboard = (props: any) => {
	const {isLoggedIn, loading, isNewUser} = useContext(userContext);
	const width = useWindowSize();

	const sidebarRef = useRef<HTMLDivElement>(null);
	const tab = props.tab;

	window.addEventListener('resize', () => {
		if (window.innerWidth > 862) openSidebar();
		else closeSidebar();
	});

	const openSidebar = () => {
		if (sidebarRef && sidebarRef.current) {
			sidebarRef.current.style.transform = 'translate(0px)';
		}
	};

	const closeSidebar = () => {
		if (window.innerWidth > 862) return;
		if (sidebarRef && sidebarRef.current) {
			sidebarRef.current.style.transform = 'translate(-420px)';
		}
	};

	if (loading) return <Spinner />;
	if (!isLoggedIn) return <Redirect to="/" />;
	if (isNewUser) return <Redirect to="/auth/info" />;

	return (
		<div className={styles.root}>
			<nav className={styles.miniSidebar}>
				<Icon
					style={{cursor: 'pointer', marginBottom: '1em'}}
					name="sidebar"
					inverted
					size="large"
					onClick={openSidebar}
				/>
				<CustomSidebar tab={tab} width={width} />
			</nav>
			<nav ref={sidebarRef} className={styles.sidebar}>
				<CustomSidebar tab={tab} width={10000} />
			</nav>
			<main className={styles.main} onClick={closeSidebar}>
				<div className={styles.menuContainer}>
					{tab === 'Announcements' && <Announcements />}
					{tab === 'Tasks' && <Tasks />}
					{tab === 'Change Domains' && <ChangeDomains />}
					{tab === 'Submissions' && <Submissions />}
					{(tab === 'Mentors' || tab === 'Mentees') && <MentorsMentees />}
				</div>
			</main>
		</div>
	);
};
