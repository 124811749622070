import React, {useContext} from 'react';
import styles from '../admin.module.css';
import {Grid, Table} from 'semantic-ui-react';
import {Loader} from '@react-three/drei';
import {AdminContext} from '../AdminContext';
interface mentorWithMentees {
	id: number; // mentor id
	name: string; // mentor name
	mentees: any[]; // will do this later, list of mentees
}

function AllocationTab({domainList}: any) {
	const {mentorId, menteeId, setMentorId, setMenteeId} = useContext(AdminContext);

	const handleMenteeId = (e: any) => {
		const id = e.target.getAttribute('menteeid');
		if (menteeId === id.toString()) {
			return setMenteeId(null);
		}
		setMenteeId(id);
	};

	const handleMentorId = (e: any) => {
		const id = e.target.getAttribute('mentorid');
		if (mentorId === id.toString()) {
			return setMentorId(null);
		}
		setMentorId(e.target.getAttribute('mentorid'));
	};

	if (!domainList) {
		return <Loader />;
	}
	if (!domainList.length) {
		return <h3 className={styles.h3}>Mentors Not Allocated!</h3>;
	}

	return (
		<Grid className={styles.allocationTab} doubling columns={3}>
			{domainList.map(({id, name, mentees}: mentorWithMentees, i: number) => (
				<Grid.Column key={i}>
					<Table inverted>
						<Table.Header>
							<Table.Row className={styles.mobiletd}>
								<Table.HeaderCell
									className={mentorId === id.toString() ? styles.active : ''}
									mentorid={`${id}`}
									onClick={handleMentorId}>
									{name}
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{mentees.map((mentee: any) => (
								<Table.Row className={styles.mobileth} key={mentee.hashedEmail}>
									<Table.Cell
										className={`${menteeId === mentee.id.toString() ? styles.active : ''} ${
											styles.cell
										}`}
										menteeid={`${mentee.id}`}
										onClick={handleMenteeId}>
										{mentee.name}
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Grid.Column>
			))}
		</Grid>
	);
}

export default AllocationTab;
