import React, {useEffect, useRef} from 'react';
import styles from './customAccordion.module.css';

export const CustomAccordion = (props: any) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const panelRef = useRef<HTMLDivElement>(null);

	const defaultOpen = props.defaultOpen;

	const onAccordionClick = () => {
		if (buttonRef && buttonRef.current) {
			buttonRef.current.classList.toggle(styles.active);
			if (panelRef && panelRef.current) {
				if (!panelRef.current.style.maxHeight || panelRef.current.style.maxHeight === '0px') {
					panelRef.current.style.maxHeight = `${panelRef.current.scrollHeight * 1.2}px`;
				} else {
					panelRef.current.style.maxHeight = '0px';
				}
			}
		}
	};

	useEffect(() => {
		if (defaultOpen) onAccordionClick();
	}, [defaultOpen]);

	return (
		<div>
			<button ref={buttonRef} onClick={onAccordionClick} className={styles.accordion}>
				{props.title}
			</button>
			<div ref={panelRef} className={styles.panel}>
				{props.children}
			</div>
		</div>
	);
};
