import React, {createContext, useState, useCallback} from 'react';

import {axiosInstance} from '../../utils/axios';
import {toast} from '../Toast/useToast';

export const AdminContext = createContext<any>(null);

const AdminContextProvider: React.FC = ({children}) => {
	// mentors,mentee,domainLetter state
	// for move Mentee handle
	const [mentorId, setMentorId]: [string | null, (mentorId: string | null) => void] = useState<string | null>(null);
	const [menteeId, setMenteeId]: [string | null, (mentorId: string | null) => void] = useState<string | null>(null);
	const [domainLetter, setDomainLetter]: [string | null, (d: string | null) => void] = useState<string | null>(null);

	// to run sideeffect(getfinalMentorsWithMentees) manually
	// it's not the better way to do it :(
	const [run, setRun] = useState(0);

	const clearState = () => {
		setMentorId(null);
		setMenteeId(null);
	};

	// initial alloction handle to initialise allocation
	const handleAllocation = useCallback(() => {
		axiosInstance
			.get(`/allocate/initial`)
			.then((response) => {
				setRun((prevState) => prevState + 1);
				toast.dark(response.data.message);
			})
			.catch((e) => {
				if (e.message) {
					toast.error(e.message);
				} else {
					toast.error(e.response);
				}
			});
	}, []);

	// extra allocation handle
	const handleExtraAllocation = useCallback(() => {
		axiosInstance
			.get(`/allocate/extra`)
			.then((response) => {
				setRun((prevState) => prevState + 1);
				clearState();
				toast.dark(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				if (e.message) {
					toast.error(e.message);
				} else {
					toast.error(e.response);
				}
			});
	}, []);

	// final allocation handle
	const handleFinalAllocation = useCallback(() => {
		axiosInstance
			.get(`/allocate/finalise`)
			.then((response) => {
				toast.dark(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				if (e.message) {
					toast.error(e.message);
				} else {
					toast.error(e.response);
				}
			});
	}, []);

	// move Mentee handle
	const handleMoveMentee = useCallback(() => {
		if (mentorId === null || menteeId === null) {
			toast.warn('select both mentee and mentor');
			return;
		}
		axiosInstance
			.post(`/allocate/moveMentee`, {
				menteeId: menteeId,
				newMentorId: mentorId,
				domainLetter: domainLetter,
			})
			.then((response) => {
				setRun((prevState) => prevState + 1);
				clearState();
				toast.dark(response.data.message);
			})
			.catch((e) => {
				console.log(e);
				if (e.message) {
					toast.error(e.message);
				} else {
					toast.error(e.response);
				}
			});
	}, [domainLetter, menteeId, mentorId]);

	return (
		<AdminContext.Provider
			value={{
				mentorId,
				menteeId,
				run,
				setDomainLetter,
				setMentorId,
				setMenteeId,
				clearState,
				handleAllocation,
				handleExtraAllocation,
				handleFinalAllocation,
				handleMoveMentee,
			}}>
			{children}
		</AdminContext.Provider>
	);
};

export default AdminContextProvider;
