import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {Button, Divider, Grid, Menu} from 'semantic-ui-react';
import Spinner from '../../../components/Spinner';
import {toast} from '../../../components/Toast/useToast';
import {UserCard} from '../../../components/UserCard/UserCard';
import {IUser, userContext} from '../../../contexts/UserContext';
import {axiosInstance} from '../../../utils/axios';

interface UserWithDomain {
	domain: string;
	user: IUser;
}

export const MentorsMentees = () => {
	const [dashboardRes, setDashboardRes] = useState<any>(null);
	const [activeItem, setActiveItem] = useState('web');
	const [loading, setLoading] = useState(true);

	const {user} = useContext(userContext);

	const history = useHistory();

	const domains: string[] = dashboardRes ? dashboardRes.userDomains.map((domain: any) => domain.shortName) : [];

	const onMenuItemClick = (e: any, item: any) => {
		setActiveItem(item.name);
	};

	const downloadContacts = async () => {
		try {
			const res = await axiosInstance.get(`/dashboard/${user.id}/vcard`, {responseType: 'blob'});
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'contacts.vcf');
			link.click();
			console.log(res);
		} catch (err) {
			if (err.response) {
				if (err.response.status === 401) return history.push('/');
				else if (err.response.status === 403) return toast.error("You cannot view this person's vcard");
				return toast.error('Something went wrong, try again later.');
			}
			if (err?.message === 'Network Error')
				return toast.error('Check your internet connection, or try again later.');
			toast.error('Something went wrong, try again later. Error: ' + err);
			toast.error('If the error persists contact the event administrator.');
		}
	};

	useEffect(() => {
		axiosInstance
			.get(`/dashboard/${user.id}`)
			.then((res) => {
				setDashboardRes(res.data);
				setActiveItem(res.data.userDomains[0]?.shortName);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toast.error('Something went wrong! Please try later');
				setLoading(false);
			});
	}, [user]);

	if (loading) return <Spinner />;

	const mentors = dashboardRes.mentors?.flatMap((mentor: any) =>
		mentor.mentors[0]
			? {
					domain: mentor.shortName,
					user: mentor.mentors[0],
			  }
			: []
	);

	const mentees = dashboardRes.mentees?.flatMap((mentee: any) =>
		mentee.mentees.flatMap((user: IUser) =>
			user
				? {
						domain: mentee.shortName,
						user: user,
				  }
				: []
		)
	);

	const allocation = (mentors && mentors.length !== 0) || (mentees && mentees.length !== 0);

	return (
		<div>
			<div style={window.innerWidth > 400 ? {display: 'flex', justifyContent: 'space-between'} : {}}>
				<h1 style={{margin: '4px 0'}}>{user.role === 'mentee' ? 'Your Mentors' : 'Your Mentees'}</h1>
				{allocation && <Button inverted color="green" content="Download Contacts" onClick={downloadContacts} />}
			</div>
			<Divider />

			{!allocation && (
				<div style={{textAlign: 'center', marginTop: '4rem'}}>
					Mentor allocation has not taken place yet! Please stay tuned.
				</div>
			)}

			{allocation && user.role !== 'mentee' && (
				<div
					style={{overflow: 'hidden', WebkitFilter: 'blur(0px)', marginBottom: '24px'}}
					className={`ui ${
						domains.length === 1 ? 'one' : domains.length === 2 ? 'two' : 'three'
					} item menu inverted`}>
					{domains.map((domain: string) =>
						domain === 'web' ? (
							<Menu.Item
								key="web"
								name="web"
								color="green"
								active={activeItem === 'web'}
								onClick={onMenuItemClick}
								content="WebDev"
							/>
						) : domain === 'app' ? (
							<Menu.Item
								key="app"
								name="app"
								color="green"
								active={activeItem === 'app'}
								onClick={onMenuItemClick}
								content="AppDev"
							/>
						) : (
							<Menu.Item
								key="sysad"
								name="sysad"
								color="green"
								active={activeItem === 'sysad'}
								onClick={onMenuItemClick}
								content="SysAd"
							/>
						)
					)}
				</div>
			)}

			{allocation && (
				<Grid columns={window.innerWidth > 1271 ? 2 : 1}>
					{mentors?.map((mentor: UserWithDomain) => {
						if (mentor.user) {
							return (
								<Grid.Column key={mentor.user.id}>
									<UserCard user={mentor.user} domain={mentor.domain} />
								</Grid.Column>
							);
						} else return null;
					})}
					{mentees?.map((mentee: UserWithDomain) => {
						if (mentee.user && mentee.domain === activeItem) {
							return (
								<Grid.Column key={mentee.user.id}>
									<UserCard user={mentee.user} domain={mentee.domain} />
								</Grid.Column>
							);
						} else return null;
					})}
				</Grid>
			)}
		</div>
	);
};
