import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {AuthContainer} from './components/Auth/AuthContainer';
import {Dashboard} from './pages/dashboard';
import './App.css';
import UserContextProvider from './contexts/UserContext';
import ConstantContextProvider from './contexts/ConstantContext';
import DomainsContextProvider from './contexts/DomainsContext';
import Toast from './components/Toast/Toast';
import MadeWithLove from './components/MadeWithLove/MadeWithLove';
import {Profile} from './components/Profile/Profile';
import {EditProfile} from './components/Profile/EditProfile';
import Admin from './components/Admin/Admin';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Home from './pages/Home/Home';

const App = () => {
	return (
		<div className="App">
			<Router>
				<ConstantContextProvider>
					<DomainsContextProvider>
						<UserContextProvider>
							<Switch>
								<Route path="/auth">
									<AuthContainer />
								</Route>
								<Route exact path="/">
									<Home />
								</Route>
								<Route path="/admin">
									<Admin />
								</Route>
								<Route exact path="/dashboard">
									<Dashboard tab="Announcements" />
								</Route>
								<Route exact path="/tasks">
									<Dashboard tab="Tasks" />
								</Route>
								<Route exact path="/changeDomains">
									<Dashboard tab="Change Domains" />
								</Route>
								<Route exact path="/mentee/submissions">
									<Dashboard tab="Submissions" />
								</Route>
								<Route exact path="/mentor/submissions">
									<Dashboard tab="Submissions" />
								</Route>
								<Route exact path="/mentor/mentees">
									<Dashboard tab="Mentees" />
								</Route>
								<Route exact path="/mentee/mentors">
									<Dashboard tab="Mentors" />
								</Route>
								<Route exact path="/profile/edit">
									<EditProfile />
								</Route>
								<Route exact path="/profile/:id">
									<Profile />
								</Route>
								<Route path="*">
									<PageNotFound />
								</Route>
							</Switch>
						</UserContextProvider>
					</DomainsContextProvider>
				</ConstantContextProvider>
				<MadeWithLove />
			</Router>
			{/* toast conatiner */}
			<Toast />
		</div>
	);
};

export default App;
