import React, {useState} from 'react';
import {CustomAccordion} from '../../../../components/CustomAccordion';
import {MentorSubmissionCard} from '../../../../components/SubmissionCard';
import {ISubmission, ISubmissionWithDomain} from '../../../../components/SubmissionCard/submissionsInterfaces';
import styles from './mentorSub.module.css';

export const MentorSubmissions = (props: any) => {
	let flag = -1;
	const [submissions, setSubmissions] = useState<ISubmissionWithDomain[]>(props.dashboardRes.submissions);
	const printSubmissions = () => console.log(props.dashboardRes.submissions);
	const setSubmissionReviewStatus = (submission: ISubmission) => {
		// updates the given submission in the submissions state
		const copiedSubmissions: ISubmissionWithDomain[] = JSON.parse(JSON.stringify(submissions));

		copiedSubmissions.every((copiedSubmission) => {
			for (let i = 0; i < copiedSubmission.submissions.length; i++) {
				if (copiedSubmission.submissions[i].id === submission.id) {
					copiedSubmission.submissions[i] = submission;
					return false; // to break out of every method
				}
			}
			return true;
		});
		setSubmissions(copiedSubmissions);
	};
	return (
		<div>
			<h3 className={styles.reviewHeader}>Not Reviewed:</h3>
			{submissions.map((domain) => {
				if (domain.shortName !== props.domain) return null;
				return domain.submissions.map((submission: ISubmission) => {
					if (submission.task && flag === -1) flag = 0;
					if (submission.reviewed === 'NOT_REVIEWED') {
						flag = 1;
						return (
							<CustomAccordion
								key={submission.id}
								title={`${submission.task.name} - ${submission.user.name}`}>
								<MentorSubmissionCard
									submission={submission}
									task={submission.task}
									printSubmissions={printSubmissions}
									setSubmissionReviewStatus={setSubmissionReviewStatus}
								/>
							</CustomAccordion>
						);
					} else return null;
				});
			})}
			{flag === -1 && (
				<div style={{textAlign: 'center', margin: '24px'}}>You have not received any submissions so far.</div>
			)}
			{flag === 0 && (
				<div style={{textAlign: 'center', margin: '24px'}}>
					You have reviewed all your submissions! Nice job fren.
				</div>
			)}
			<h3 className={styles.reviewHeader}>Reviewed:</h3>
			{submissions.map((domain: ISubmissionWithDomain) => {
				if (domain.shortName !== props.domain) return null;
				return domain.submissions.map((submission: ISubmission) => {
					if (submission.reviewed !== 'NOT_REVIEWED')
						return (
							<CustomAccordion
								key={submission.id}
								title={`${submission.task.name} - ${submission.user.name} (${submission.reviewed})`}>
								<MentorSubmissionCard
									submission={submission}
									task={submission.task}
									printSubmissions={printSubmissions}
									setSubmissionReviewStatus={setSubmissionReviewStatus}
								/>
							</CustomAccordion>
						);
					else return null;
				});
			})}
		</div>
	);
};
