import React, {useContext, useEffect, useState} from 'react';
import {Divider, Menu} from 'semantic-ui-react';
import Spinner from '../../../components/Spinner';
import {userContext} from '../../../contexts/UserContext';
import {axiosInstance} from '../../../utils/axios';
import {MenteeSubmissions} from './mentee/menteeSubmissions';
import {MentorSubmissions} from './mentor/mentorSubmissions';
import styles from './submissions.module.css';

export const Submissions = () => {
	const [activeItem, setActiveItem] = useState('web');
	const [dashboardRes, setDashboardRes] = useState<any>(null);
	const [loading, setLoading] = useState(true);

	const {user} = useContext(userContext);
	const domains: string[] = dashboardRes ? dashboardRes.userDomains.map((domain: any) => domain.shortName) : [];

	useEffect(() => {
		const fetchDashboard = async () => await axiosInstance.get(`/dashboard/${user.id}`);
		fetchDashboard()
			.then((res) => {
				setDashboardRes(res.data);
				setActiveItem(res.data.userDomains[0]?.shortName);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}, [user.id]);

	const onMenuItemClick = (e: any, item: any) => {
		setActiveItem(item.name);
	};

	if (loading) return <Spinner />;

	return (
		<div className={styles.root}>
			<h1>Submissions</h1>
			<Divider />

			<div
				style={{overflow: 'hidden', marginBottom: dashboardRes.isMentor ? '16px' : '32px'}}
				className={`ui ${
					domains.length === 1 ? 'one' : domains.length === 2 ? 'two' : 'three'
				} item menu inverted`}>
				{domains.map((domain: string) =>
					domain === 'web' ? (
						<Menu.Item
							key="web"
							name="web"
							color="green"
							active={activeItem === 'web'}
							onClick={onMenuItemClick}
							content="WebDev"
						/>
					) : domain === 'app' ? (
						<Menu.Item
							key="app"
							name="app"
							color="green"
							active={activeItem === 'app'}
							onClick={onMenuItemClick}
							content="AppDev"
						/>
					) : (
						<Menu.Item
							key="sysad"
							name="sysad"
							color="green"
							active={activeItem === 'sysad'}
							onClick={onMenuItemClick}
							content="SysAd"
						/>
					)
				)}
			</div>

			{dashboardRes.isMentor ? (
				<MentorSubmissions dashboardRes={dashboardRes} domain={activeItem} />
			) : (
				<MenteeSubmissions dashboardRes={dashboardRes} domain={activeItem} />
			)}
		</div>
	);
};
