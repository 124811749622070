import React, {useContext} from 'react';
import {Redirect} from 'react-router';
import {ConstantContext} from '../../contexts/ConstantContext';
import {DomainsContext} from '../../contexts/DomainsContext';
import {userContext} from '../../contexts/UserContext';
import Spinner from '../Spinner';
import {toast} from '../Toast/useToast';
import AdminDashboard from './Dashboard/AdminDashboard';

const Admin: React.FC = () => {
	const constants = useContext(ConstantContext);
	const domains = useContext(DomainsContext);
	const {user, loading} = useContext(userContext);

	if (domains.loading && constants.loading && loading) {
		return <Spinner />;
	}
	// not logged in, back to home
	if (user === undefined) {
		return <Redirect to="/" />;
	}
	// not admin, back to dashboard
	if (user.role !== 'admin') {
		toast.dark('back to dashboard');
		return <Redirect to="/dashboard" />;
	}
	return <AdminDashboard />;
};

export default Admin;
