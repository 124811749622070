import React, {useContext, useEffect, useState} from 'react';
import {Header, Icon, Loader, Menu, Statistic} from 'semantic-ui-react';
import {SemanticICONS} from 'semantic-ui-react/dist/commonjs/generic';
import styles from './sidebar.module.css';
import {menuItemsInfo} from './menuItemsInfo';
import {useHistory} from 'react-router';
import {axiosInstance} from '../../utils/axios';
import {userContext} from '../../contexts/UserContext';
import {toast} from '../../components/Toast/useToast';
import {Link} from 'react-router-dom';

export const CustomSidebar = (props: any) => {
	const [dashboardRes, setDashboardRes] = useState<any>({});
	const history = useHistory();

	const {user, deleteUser, loading} = useContext(userContext);

	const tabs = dashboardRes['tabs'];
	const stats = dashboardRes['stats'];
	const domains = dashboardRes.userDomains ? dashboardRes.userDomains.map((domain: any) => domain.shortName) : [];

	useEffect(() => {
		const fetchDashboard = async () => await axiosInstance.get(`/dashboard/${user.id}`);
		fetchDashboard()
			.then((res) => {
				setDashboardRes(res.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [user]);

	if (loading) return <Loader />;

	const hasKey = <O,>(obj: O, key: keyof any): key is keyof O => key in obj;

	const menuOnClick = (e: any, item: any) => {
		history.push(item.location);
	};

	const handleLogout = () => {
		axiosInstance
			.post('/auth/logout')
			.then((res) => {
				history.push('/');
				deleteUser();
				toast.dark('Logged out successfully');
			})
			.catch((err) => console.log(err));
	};

	const menu = (
		<Menu style={{background: '#282828'}} vertical inverted fluid>
			{tabs &&
				tabs.map((tab: string) => {
					if (hasKey(menuItemsInfo, tab)) {
						const item = menuItemsInfo[tab];
						return (
							<Menu.Item
								color="green"
								name={item.name}
								location={item.location}
								className={styles.menuItem}
								key={item.name}
								active={props.tab === item.name}
								onClick={menuOnClick}>
								<Icon className={styles.menuIcon} name={item.iconName as SemanticICONS} />
								{props.width > 862 ? item.name : ''}
							</Menu.Item>
						);
					}
					return null;
				})}
			{user.role === 'admin' && (
				<Menu.Item className={styles.menuItem} active={false} onClick={() => history.push('/admin')}>
					<Icon className={styles.menuIcon} name="universal access" />
					{props.width > 862 ? 'Admin' : ''}
				</Menu.Item>
			)}

			<Menu.Item color="green" name="Logout" className={styles.menuItem} active={false} onClick={handleLogout}>
				<Icon className={styles.menuIcon} name="log out" />
				{props.width > 862 ? 'Logout' : ''}
			</Menu.Item>
		</Menu>
	);

	if (props.width > 862) {
		return (
			<div className={styles.rootContainer}>
				<div className={styles.userDetails}>
					<img
						className={styles.identicon}
						src={`https://gravatar.com/avatar/${user.hashedEmail}.jpg?d=retro`}
						alt="Error loading identicon :("
					/>
					<div
						style={{
							width: '150px',
							background: '#28A315',
							color: '#ffffff',
							textAlign: 'center',
						}}>
						<b>{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</b>
					</div>
					<Header content={user.name} inverted />
					<div className={styles.domains}>
						{domains.map((domain: string) =>
							domain === 'web' ? (
								<Icon className={styles.domainIcons} key="w" name="computer" size="large" />
							) : domain === 'app' ? (
								<Icon className={styles.domainIcons} key="m" name="mobile alternate" size="large" />
							) : (
								<Icon className={styles.domainIcons} key="s" name="terminal" size="large" />
							)
						)}
					</div>
					<Link to={`/profile/${user.id}`}>Visit Profile</Link>
				</div>
				<div className={styles.stats}>
					<Statistic.Group widths="3" className={styles.statsGroup} size="tiny" inverted>
						{stats && dashboardRes.isMentor && (
							<>
								<Statistic value={stats.menteesCount} label="Mentees" />
								<Statistic value={stats.submissionsCount} label="Submissions" />
								<Statistic value={stats.reviewedCount} label="Reviewed" />
							</>
						)}
					</Statistic.Group>
				</div>
				<div className={styles.menu}>{menu}</div>
			</div>
		);
	} else {
		return menu;
	}
};
