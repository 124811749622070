export const menuItemsInfo = {
	announcements: {
		name: 'Announcements',
		location: '/dashboard',
		iconName: 'bell',
	},
	tasks: {
		name: 'Tasks',
		location: '/tasks',
		iconName: 'tasks',
	},
	mentees: {
		name: 'Mentees',
		location: '/mentor/mentees',
		iconName: 'users',
	},
	mentor_submissions: {
		name: 'Submissions',
		location: '/mentor/submissions',
		iconName: 'newspaper',
	},
	mentors: {
		name: 'Mentors',
		location: '/mentee/mentors',
		iconName: 'user',
	},
	mentee_submissions: {
		name: 'Submissions',
		location: '/mentee/submissions',
		iconName: 'newspaper',
	},
	swap_mentees: {
		name: 'Swap Mentees',
		location: '', // TODO
		iconName: 'exchange',
	},
	change_domain: {
		name: 'Change Domains',
		location: '/changeDomains',
		iconName: 'exchange',
	},
	logout: {
		name: 'Logout',
		location: '/',
		iconName: 'exchange',
	},
};