import React, {useContext} from 'react';
import {ConstantContext} from '../../../contexts/ConstantContext';
import {DomainsContext} from '../../../contexts/DomainsContext';
import Spinner from '../../Spinner';
import AdminContextProvider from '../AdminContext';
import Allocation from './Allocation';
import NavBar from '../Menu/NavBar';
import SideBar from '../Menu/SideBar';

import styles from '../admin.module.css';

function AdminDashboard() {
	const constants = useContext(ConstantContext);
	const domain = useContext(DomainsContext);

	if (domain.loading && constants.loading) {
		return <Spinner />;
	}

	return (
		<AdminContextProvider>
			<NavBar />
			<SideBar />
			<div className={styles.allocationConatiner}>
				<Allocation domains={domain.domains} />
			</div>
		</AdminContextProvider>
	);
}

export default AdminDashboard;
