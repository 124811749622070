import webdevimage from '../../../images/webdev.png'
import appdevimage from '../../../images/appdev.png'
import sysadimage from '../../../images/sysad.png'


export const domains = [
    {
        title : "<WebDev/>",
        image: webdevimage,
        content: "As full-stack developers we take care of building aesthetic user interfaces and an intuitive user experience along with a robust backend that can serve personalized content to each user. By trying everything new in the field, we evolve along with the industry"
    },
    {
        title : "AppDev",
        image: appdevimage,
        content: "Create innovative, user-friendly, and cutting-edge mobile applications that shape the future. Our dynamic App Developers are the brilliant minds behind the immersive and intuitive Android apps for Festember, Pragyan, and many more! If you have a passion for crafting groundbreaking apps that will become the talk of the town, sign up for this profile and let your creativity run wild!"
    },
    {
        title : "~/SysAd>$",
        image: sysadimage,
        content: "SysAds are the people responsible for your project getting into production. With a passion for technology and a preference for the CLI over the GUI, they manage servers, troubleshoot technical issues, and ensure that systems are secure and running smoothly. If you would love to learn the intricacies involved in working with servers and the web, this profile may be perfect for you."
    }
]