import React from 'react';
import {useRef} from 'react';
import styles from './ProjectPage.module.css';
import {projects, Project} from './Projects';

import {motion, useScroll, useTransform, MotionValue} from 'framer-motion/dist/framer-motion';

function useParallax(value: MotionValue<number>, distance: number) {
	return useTransform(value, [0, 1], [-distance, distance]);
}

function decidescrollOffset(width: number) {
	if (width > 600) return 400;
	else if (width < 400) return 50;
	else return 15;
}

function ProjectContainer({project, isFirstProject}: {project: Project; isFirstProject: boolean}) {
	const ref = useRef(null);
	const {scrollYProgress} = useScroll({target: ref});

	const y = useParallax(scrollYProgress, decidescrollOffset(window.innerWidth));

	return (
		<section>
			{isFirstProject && <div className={styles.titleDiv}>Projects</div>}
			<motion.div className={styles.parallaxDiv} style={{y}}>
				{project.name}
			</motion.div>
			<div className={styles.parallaxImgContainer} ref={ref}>
				<img className={styles.parallaxImg} src={project.image} alt="A Really Cool Project" />
			</div>
			<motion.div className={styles.parallaxContent} style={{y}}>
				{project.description}
			</motion.div>
		</section>
	);
}

function ProjectsPage() {
	return (
		<>
			<div>
				{projects.map((project, index) => (
					<ProjectContainer project={project} isFirstProject={index === 0} />
				))}
			</div>
		</>
	);
}

export default ProjectsPage;
