import React, {useContext, useEffect, useState} from 'react';
import {Link, Redirect, useHistory, useParams} from 'react-router-dom';
import {Container, Header, Image, Card, Icon, Feed, Button, Popup, Radio} from 'semantic-ui-react';
import {IUser, IUserContext, userContext} from '../../contexts/UserContext';
import {axiosInstance} from '../../utils/axios';
import Spinner from '../Spinner';
import {toast} from '../Toast/useToast';
import styles from './Profile.module.css';

type ProfileProps = {
	rollNo?: number;
};

const Profile = (props: ProfileProps) => {
	const {isLoggedIn, loading} = useContext(userContext) as IUserContext;
	const [domains, setDomains] = useState<any>([]);
	const [isMentoring, setIsMentoring] = useState(false);
	const [user, setUser] = useState<IUser | null>(null);
	const [isOwnProfile, setIsOwnProfile] = useState(false);
	const history = useHistory();
	const {id} = useParams<{id: string}>();

	useEffect(() => {
		// TODO: Add a 404 page instead of redirect
		if (!Number(id)) return history.push('/dashboard');
		axiosInstance
			.get(`/profile/${id}`)
			.then((data) => {
				data?.data?.ownProfile ? setUser(data?.data?.user) : setUser(data?.data?.userDetails);
				setDomains(data?.data?.userDomains);
				setIsMentoring(data?.data?.isMentor);
				setIsOwnProfile(data?.data?.ownProfile);
				process.env.NODE_ENV === 'development' && console.log(data?.data);
			})
			.catch((err) => {
				if (err.response) {
					if (err.response.status === 401) return history.push('/auth/login');
					if (err.response.status === 403) return history.push('/dashboard');
					return toast.error('Something, went wrong try again later.');
				}
				if (err?.message === 'Network Error')
					return toast.error('Check your internet connection, or try again later.');
				toast.error('Something went wrong, try again later. Error: ' + err);
				toast.error('If the error persists contact the event administrator.');
			});
	}, [history, id]);

	const getvCard = async () => {
		try {
			const resp = await axiosInstance.get(`/dashboard/${id}/user_vcard`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([resp.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'contacts.vcf');
			link.click();
			console.log(resp);
		} catch (err) {
			if (err.response) {
				if (err.response.status === 401) return history.push('/auth/login');
				else if (err.response.status === 403) return toast.error("You cannot view this person's vcard");

				return toast.error('Something, went wrong try again later.');
			}
			if (err?.message === 'Network Error')
				return toast.error('Check your internet connection, or try again later.');
			toast.error('Something went wrong, try again later. Error: ' + err);
			toast.error('If the error persists contact the event administrator.');
		}
	};

	const alumniOptIn = async () => {
		try {
			await axiosInstance.post('/auth/toggle_opt_in');
			setIsMentoring(!isMentoring);
			return toast.success('Successfully, changed your preference.');
		} catch (err) {
			if (err.response) {
				if (err.response.status === 401)
					if (err.response.data.message === 'user not authenticated') return toast.warn('Log in agai. LOL');
					else return toast.success('Only Delta alumni can mentor people.');
				return toast.error('Something went wrong. Try again later.');
			}
			if (err.message === 'Network Error')
				return toast.error('Check your internet connection, or try again later.');
			toast.error('Something went wrong, try again later. Error: ' + err);
			toast.error('If the error persists contact the event administrator.');
		}
	};

	if (loading) return <Spinner />;

	if (!isLoggedIn) return <Redirect to="/auth/login" />;

	return (
		<div className={styles.flexBox}>
			<Container textAlign="center" as="div" className={styles.profileContainer}>
				<Header
					as="h1"
					size="huge"
					content={isOwnProfile ? 'Your Profile' : `${user?.name}'s Profile`}
					textAlign="center"
				/>
				<div className={styles.profileSubContainer}>
					<div>
						<Card centered className={styles.styledCards}>
							<Image
								src={`https://gravatar.com/avatar/${user?.hashedEmail}.jpg?d=retro`}
								wrapped
								rounded
								className={styles.profileImage}
								data-role={user?.role}
								ui={false}
							/>
							<Card.Content>
								<Card.Header>{user?.name}</Card.Header>
								<Card.Description>{user?.rollNumber}</Card.Description>
							</Card.Content>
							<Card.Content extra>
								<>
									<h4>DOMAINS</h4>
									{/* <br /> */}
									<>
										{domains.map((dom: any, ind: number) => {
											if (dom.shortName === 'web')
												return (
													<Popup
														content="Web Development"
														position="top center"
														trigger={
															<Icon
																name="desktop"
																size="big"
																style={{margin: '0 1rem'}}
																key={ind}
															/>
														}
													/>
												);
											else if (dom.shortName === 'app')
												return (
													<Popup
														content="App Development"
														position="top center"
														trigger={
															<Icon
																name="mobile alternate"
																size="big"
																style={{margin: '0 1rem'}}
																key={ind}
															/>
														}
													/>
												);
											else if (dom.shortName === 'sysad')
												return (
													<Popup
														content="System Adminstration"
														position="top center"
														trigger={
															<Icon
																name="terminal"
																size="big"
																style={{margin: '0 1rem'}}
																key={ind}
															/>
														}
													/>
												);
											return <></>;
										})}
									</>
								</>
							</Card.Content>
						</Card>
					</div>
					<div>
						{/* <Header as="h1" size="large" content="Details" textAlign="center" /> */}

						<Card centered className={styles.styledCards} size="large">
							<Card.Content>
								{user?.role === 'alumni' && (
									<>
										<Card.Header as="h2" size="large">
											Do you want to Mentor ?
										</Card.Header>
										<Card.Description>
											<Radio
												toggle
												type="checkbox"
												checked={isMentoring}
												onChange={alumniOptIn}
											/>
										</Card.Description>
										<br />
									</>
								)}
								<Card.Header as="h2" size="large">
									Interests
								</Card.Header>
								<Card.Description>{user?.interests}</Card.Description>
							</Card.Content>
							<Card.Content>
								<Card.Header as="h6">Contact Information</Card.Header>
								<Feed>
									<Feed.Event>
										<Feed.Label icon="phone" />
										<Feed.Content>
											<Feed.Date content="Contact Number" />
											<Feed.Summary>
												<a href={`tel:+${user?.contactNumber}`}>{user?.contactNumber}</a>
											</Feed.Summary>
										</Feed.Content>
									</Feed.Event>

									<Feed.Event>
										<Feed.Label icon="mail" />
										<Feed.Content>
											<Feed.Date content="Email" />
											<Feed.Summary>
												<a href={`mailto:${user?.email}`}>{user?.email}</a>
											</Feed.Summary>
										</Feed.Content>
									</Feed.Event>

									<Feed.Event>
										<Feed.Label icon="github" />
										<Feed.Content>
											<Feed.Date content="GitHub" />
											<Feed.Summary>
												<a
													href={`http://github.com/${user?.github}`}
													target="_blank"
													rel="noopener noreferrer">
													{user?.github}
												</a>
											</Feed.Summary>
										</Feed.Content>
									</Feed.Event>
								</Feed>
							</Card.Content>
						</Card>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-around',
						alignItems: 'center',
						flexWrap: 'wrap',
						marginTop: '1rem',
						marginBottom: '4rem',
					}}>
					{isOwnProfile && (
						<Popup
							content="Edit your profile."
							position="left center"
							trigger={
								<Button inverted as={Link} to="/profile/edit" color="blue" style={{margin: '1rem'}}>
									<Icon name="edit" />
									Edit Profile
								</Button>
							}
						/>
					)}
					<Popup
						content="Download your contact information as a vcf file."
						position="right center"
						trigger={
							<Button inverted color="green" style={{margin: '1rem'}} onClick={getvCard}>
								<Icon name="download" />
								Download VCard
							</Button>
						}
					/>
				</div>

				<Link to="/dashboard">Back to Dashboard</Link>
			</Container>
		</div>
	);
};

export {Profile};
