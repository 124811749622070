import React, {useRef, useState} from 'react';
import {Button, Form, TextArea} from 'semantic-ui-react';
import {axiosInstance} from '../../utils/axios';
import {toast} from '../Toast/useToast';
import styles from './SubmissionCard.module.css';
import {ISubmission, ITask} from './submissionsInterfaces';

interface IProps {
	submission: ISubmission;
	task: ITask;
}

export const MenteeSubmissionCard = ({submission, task}: IProps) => {
	const statusRef = useRef<HTMLSpanElement>(null);
	const [submitted, setSubmitted] = useState(submission ? (submission.githubUrl ? true : false) : false);

	let repoName = '';

	const validateRepo = () => {
		return !repoName.includes('github.com');
	};

	const handleSubmit = () => {
		if (!repoName) return toast.error('Please enter github repo name of your task');
		if (!validateRepo()) return toast.error('Enter your repository name instead of its url.');
		axiosInstance
			.post('/dashboard/submitTask', {
				taskId: task.id,
				githubUrl: repoName,
			})
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					statusRef.current?.style.setProperty('color', 'green');
					setSubmitted(true);
					toast.dark('Task submitted successfully!');
				} else {
					toast.error('Something went wrong! Please try again later');
				}
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.response.message);
			});
	};

	return (
		<div className={styles.card}>
			<div style={window.innerWidth > 768 ? {display: 'flex'} : {}}>
				<Form style={{marginRight: '8px', width: '100%'}}>
					<TextArea
						rows="1"
						placeholder="Github repository name"
						onChange={(e, data) => (repoName = data.value as string)}
					/>
				</Form>
				<Button
					style={window.innerWidth > 768 ? {marginLeft: '8px'} : {marginTop: '16px'}}
					inverted
					color="green"
					content={!submitted ? 'Submit' : 'Update'}
					onClick={handleSubmit}
				/>
			</div>
			<div style={{marginTop: '1rem'}}>
				<span ref={statusRef} style={{color: submitted ? 'green' : 'red'}}>
					Status: {submitted ? 'Submitted' : 'Not Submitted'}
				</span>
			</div>
		</div>
	);
};
