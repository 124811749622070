import React, {useContext} from 'react';
import {Icon, Menu, Sidebar} from 'semantic-ui-react';
import {AdminContext} from '../AdminContext';
import styles from '../admin.module.css';

function SideBar() {
	const {handleAllocation, handleExtraAllocation, handleFinalAllocation, handleMoveMentee} = useContext(AdminContext);
	return (
		<Sidebar as={Menu} icon="labeled" inverted vertical visible={true} width="thin" className={styles.sidebar}>
			<Menu.Item className={styles.a} onClick={handleAllocation} as="a">
				<Icon name="random" />
				Redo Allocation
			</Menu.Item>
			<Menu.Item className={styles.a} onClick={handleExtraAllocation} as="a">
				<Icon name="plus circle" />
				Extra Allocation
			</Menu.Item>
			<Menu.Item className={styles.a} onClick={handleFinalAllocation} as="a">
				<Icon name="check square" />
				Final Allocation
			</Menu.Item>
			<Menu.Item className={styles.a} onClick={handleMoveMentee} as="a">
				<Icon name="truck" />
				Move Mentee
			</Menu.Item>
		</Sidebar>
	);
}

export default SideBar;
